@import "~ngx-smart-modal/ngx-smart-modal";
@import "app/app.component";


/* You can add global styles to this file, and also import other style files */
svg:not(:root).svg-inline--fa {
    overflow: visible
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em
  }

  .svg-inline--fa.fa-lg {
    vertical-align: -.225em
  }

  .svg-inline--fa.fa-w-1 {
    width: .0625em
  }

  .svg-inline--fa.fa-w-2 {
    width: .125em
  }

  .svg-inline--fa.fa-w-3 {
    width: .1875em
  }

  .svg-inline--fa.fa-w-4 {
    width: .25em
  }

  .svg-inline--fa.fa-w-5 {
    width: .3125em
  }

  .svg-inline--fa.fa-w-6 {
    width: .375em
  }

  .svg-inline--fa.fa-w-7 {
    width: .4375em
  }

  .svg-inline--fa.fa-w-8 {
    width: .5em
  }

  .svg-inline--fa.fa-w-9 {
    width: .5625em
  }

  .svg-inline--fa.fa-w-10 {
    width: .625em
  }

  .svg-inline--fa.fa-w-11 {
    width: .6875em
  }

  .svg-inline--fa.fa-w-12 {
    width: .75em
  }

  .svg-inline--fa.fa-w-13 {
    width: .8125em
  }

  .svg-inline--fa.fa-w-14 {
    width: .875em
  }

  .svg-inline--fa.fa-w-15 {
    width: .9375em
  }

  .svg-inline--fa.fa-w-16 {
    width: 1em
  }

  .svg-inline--fa.fa-w-17 {
    width: 1.0625em
  }

  .svg-inline--fa.fa-w-18 {
    width: 1.125em
  }

  .svg-inline--fa.fa-w-19 {
    width: 1.1875em
  }

  .svg-inline--fa.fa-w-20 {
    width: 1.25em
  }

  .svg-inline--fa.fa-pull-left {
    margin-right: .3em;
    width: auto
  }

  .svg-inline--fa.fa-pull-right {
    margin-left: .3em;
    width: auto
  }

  .svg-inline--fa.fa-border {
    height: 1.5em
  }

  .svg-inline--fa.fa-li {
    width: 2em
  }

  .svg-inline--fa.fa-fw {
    width: 1.25em
  }

  .fa-layers svg.svg-inline--fa {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
  }

  .fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -.125em;
    width: 1em
  }

  .fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
    transform-origin: center center
  }

  .fa-layers-counter,
  .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center
  }

  .fa-layers-text {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center
  }

  .fa-layers-counter {
    background-color: #ff253a;
    border-radius: 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    height: 1.5em;
    line-height: 1;
    max-width: 5em;
    min-width: 1.5em;
    overflow: hidden;
    padding: .25em;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: top right;
    transform-origin: top right
  }

  .fa-layers-bottom-right {
    bottom: 0;
    right: 0;
    top: auto;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right
  }

  .fa-layers-bottom-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
  }

  .fa-layers-top-right {
    right: 0;
    top: 0;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: top right;
    transform-origin: top right
  }

  .fa-layers-top-left {
    left: 0;
    right: auto;
    top: 0;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: top left;
    transform-origin: top left
  }

  .fa-lg {
    font-size: 1.3333333333em;
    line-height: .75em;
    vertical-align: -.0667em
  }

  .fa-xs {
    font-size: .75em
  }

  .fa-sm {
    font-size: .875em
  }

  .fa-1x {
    font-size: 1em
  }

  .fa-2x {
    font-size: 2em
  }

  .fa-3x {
    font-size: 3em
  }

  .fa-4x {
    font-size: 4em
  }

  .fa-5x {
    font-size: 5em
  }

  .fa-6x {
    font-size: 6em
  }

  .fa-7x {
    font-size: 7em
  }

  .fa-8x {
    font-size: 8em
  }

  .fa-9x {
    font-size: 9em
  }

  .fa-10x {
    font-size: 10em
  }

  .fa-fw {
    text-align: center;
    width: 1.25em
  }

  .fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0
  }

  .fa-ul>li {
    position: relative
  }

  .fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit
  }

  .fa-border {
    border: solid .08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em
  }

  .fa-pull-left {
    float: left
  }

  .fa-pull-right {
    float: right
  }

  .fa.fa-pull-left,
  .fab.fa-pull-left,
  .fal.fa-pull-left,
  .far.fa-pull-left,
  .fas.fa-pull-left {
    margin-right: .3em
  }

  .fa.fa-pull-right,
  .fab.fa-pull-right,
  .fal.fa-pull-right,
  .far.fa-pull-right,
  .fas.fa-pull-right {
    margin-left: .3em
  }

  .fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
  }

  .fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
  }

  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  .fa-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }

  .fa-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .fa-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
  }

  .fa-flip-horizontal {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
  }

  .fa-flip-vertical {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1)
  }

  .fa-flip-both,
  .fa-flip-horizontal.fa-flip-vertical {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1)
  }

  :root .fa-flip-both,
  :root .fa-flip-horizontal,
  :root .fa-flip-vertical,
  :root .fa-rotate-180,
  :root .fa-rotate-270,
  :root .fa-rotate-90 {
    -webkit-filter: none;
    filter: none
  }

  .fa-stack {
    display: inline-block;
    height: 2em;
    position: relative;
    width: 2.5em
  }

  .fa-stack-1x,
  .fa-stack-2x {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
  }

  .svg-inline--fa.fa-stack-1x {
    height: 1em;
    width: 1.25em
  }

  .svg-inline--fa.fa-stack-2x {
    height: 2em;
    width: 2.5em
  }

  .fa-inverse {
    color: #fff
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
  }

  .svg-inline--fa .fa-primary {
    fill: var(--fa-primary-color, currentColor);
    opacity: 1;
    opacity: var(--fa-primary-opacity, 1)
  }

  .svg-inline--fa .fa-secondary {
    fill: var(--fa-secondary-color, currentColor);
    opacity: .4;
    opacity: var(--fa-secondary-opacity, .4)
  }

  .svg-inline--fa.fa-swap-opacity .fa-primary {
    opacity: .4;
    opacity: var(--fa-secondary-opacity, .4)
  }

  .svg-inline--fa.fa-swap-opacity .fa-secondary {
    opacity: 1;
    opacity: var(--fa-primary-opacity, 1)
  }

  .svg-inline--fa mask .fa-primary,
  .svg-inline--fa mask .fa-secondary {
    fill: #000
  }

  .fad.fa-inverse {
    color: #fff
  }

  body,
    html {
      margin: 0;
      height: 100%;
    }

    body.no-block {
      flex-flow: column;
      overflow-x: hidden;
      display: flex;
    }

    body {
      background: #DEDEDE;
    }

    .fp-no-block {
      align-items: center;
      justify-content: center;
      font-size: 20px;
      flex-direction: column;
      cursor: pointer;
      color: #444;
      display: none;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      text-align: center;
      background: #FFF;
      transition: margin 0.25s linear 0.25s;
    }

    .fp-no-block:hover {
      background: #EFEFEF;
    }

    .fp-no-block.fp-visible {
      display: flex;
    }

    .fp-no-sections {
      flex: 2;
    }

    .fp-no-block p {
      margin: 0;
    }

    .fp-no-sections p+p {
      font-size: 16px;
      margin-top: 5px;
    }

    .fdb-block {
      box-shadow: 0;
      margin-bottom: 0;
      transition: margin 0.25s linear 0.25s;
    }

    .fp-active {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14), 0 0px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14), 0 0px 6px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14), 0 0px 6px rgba(0, 0, 0, 0.16);
      z-index: 9999;
    }

    body.fp-add-view [data-block-type],
    body.fp-add-view .fp-no-block {
      margin-bottom: 20px;
    }

    body.fp-add-view [data-block-type] {
      user-select: none;
      position: relative;
    }

    body.fp-add-view section[data-block-type] {
      cursor: move;
    }

    body.fp-add-view [data-block-type]:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      z-index: 10000;
      content: "";
    }

    .fp-drop-placeholder {
      height: 200px;
      width: 100%;
      border: solid 10px #0098f7;
      background: #FFF;
      margin-bottom: 20px;
    }

    .fp-dragging {
      display: none;
    }

    .fr-popup {
      z-index: 10000 !important;
    }

    img.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
      vertical-align: top;
    }

    span.fr-emoticon.fr-emoticon-img {
      background-repeat: no-repeat !important;
      font-size: inherit;
      height: 1em;
      width: 1em;
      min-height: 20px;
      min-width: 20px;
      display: inline-block;
      margin: -.1em .1em .1em;
      line-height: 1;
      vertical-align: middle;
    }

    .col-6{
      flex: 0 0 33%;
      max-width: 33%;
    }

    .bg-logocolor{
      background: black;
    }

    .divcontent{
    background-color: rgba(146, 203, 238,0.75);
  transform:
    rotateX(0deg)
    rotateZ(0deg);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #f9f9fb,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    transform:
      translate3d(0px, -16px, 0px)
      rotateX(0deg)
      rotateZ(0deg);
    box-shadow:
      1px 1px 0 1px #f9f9fb,
      -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  }
    }

  .bglogo{
    background-image: url('/assets/MapperAppImages/MapperAnywhereLogo.png');
  }

  .bgbird{
    background-image: url('/assets/MapperAppImages/bluejay.png');
  }

  .bgcolor1{
    background-color: rgba(146, 203, 238, 0.25);
  }

  .bgcolor2{
    background-color: rgba(209, 234, 224, 0.7);
  }

  .fdb-icon {
    width: auto;
    width: 60px; 
  }
  
